import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./component/Home/Home";
import Login from "./component/Login/Login";
import logo from "../src/component/";
// import Cookies from 'js-cookie';

export const LogoContext = createContext();

function App() {
  // useEffect(()=>{
  //   let jwtToken = Cookies.get("jwt_Token");
  //   if(jwtToken =="" || jwtToken !== null){
  //     checkAuth();
  //   }else{
  //     window.Location.replace("http://localhost:3000")
  //   }
  // })
  const [logo, setLogo] = useState("");

  useEffect(() => {
    const hostname = window.location.hostname;

    let selectedLogo = "learnersmount";
    console.log("hostname", hostname);
    if (hostname.includes("edpedia")) {
      selectedLogo = "edpedia";
    } else if (hostname.includes("learnersmount")) {
      selectedLogo = "learnersmount";
    }

    // Update state for rendering logo
    setLogo(selectedLogo);

    // Dynamically update favicon
    const favicon = document.getElementById("dynamic-favicon");
    if (favicon) {
      favicon.href = selectedLogo;
    }
  }, []);

  return (
    <LogoContext.Provider value={{ logo }}>
      <Routes>
        <Route exact path="/" element={<Login logo={logo} />} />
        <Route exact path="/home" element={<Home />} />
        {/* <Route exact path='/home2' element={<Home2/>}/> */}
      </Routes>
    </LogoContext.Provider>
  );
}
export default App;
